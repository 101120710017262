// transparent background and border properties included for button version.
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

.btn-close {
  right: 2%;
  top: 2%;
  display: flex;
  justify-content: content;
  align-items: center;
  box-sizing: content-box;
  width: 2rem;
  height: 2rem;
  padding: $btn-close-padding-y $btn-close-padding-x;
  color: $btn-close-color;
  background: white;
  border: 0; // for button elements
  border-radius: 50%;
  opacity: $btn-close-opacity;

  // Override <a>'s hover style
  &:hover {
    color: $btn-close-color;
    text-decoration: none;
    opacity: $btn-close-hover-opacity;
  }

  &:focus {
    outline: 0;
    box-shadow: $btn-close-focus-shadow;
    opacity: $btn-close-focus-opacity;
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    user-select: none;
    opacity: $btn-close-disabled-opacity;
  }
}

.btn-close-white {
  filter: $btn-close-white-filter;
}
