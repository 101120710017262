// --------------------
// Include Fonts Here
// --------------------
@font-face {
  font-family: "Futura";
  src: url("../fonts/FuturaBT-Book.woff2") format("woff2"),
    url("../fonts/FuturaBT-Book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("../fonts/FuturaBT-Medium.woff2") format("woff2"),
    url("../fonts/FuturaBT-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("../fonts/Futura-Bold.woff2") format("woff2"),
    url("../fonts/Futura-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

$headings-font: "Futura", sans-serif;
$body-font: "Futura", sans-serif;

// --------------------
//  Include Basic Typography
// --------------------

:root {
  font-size: 62.5%; // 1rem = 10px
}

html,
body {
  @include font-smoothing("on");
  font-size: 1.6rem;
  font-family: $body-font;
  @include media-breakpoint-up(lg) {
    font-size: 1.9rem;
  }
}

p {
  color: #414142;
  font-weight: 400;
  line-height: 1.56;
  @include media-breakpoint-up(lg) {
    line-height: 1.32;
  }
}

// Headline padding
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  line-height: 1.3;
  margin-bottom: 2rem;
  font-family: $headings-font;
  font-weight: bold;
  text-transform: capitalize;
}

h1,
.h1 {
  font-size: 2.6rem;
  letter-spacing: -0.03em;

  @include media-breakpoint-up(lg) {
    font-size: 4.3rem;
    line-height: 1.19;
  }
}

h2,
.h2 {
  font-size: 2.4rem;
  line-height: 1.15;
  letter-spacing: -0.03em;
  color: #0f1132;
  @include media-breakpoint-up(lg) {
    font-size: 3.5rem;
    line-height: 1.11;
  }
}

h3,
.h3 {
  font-size: 3rem; //Laptop: 30px, Ipads: 27px, Mobiles: 24px
  color: #0f1132;
}

h4,
.h4 {
  font-size: 2.4rem; //Laptop: 24px, Ipads: 21.6px, Mobiles: 19.2px
  color: #0f1132;
}

h5,
.h5 {
  font-size: 2rem; //Laptop: 20px, Ipads: 18px, Mobiles: 16px
}

h6,
.h6 {
  font-size: 1.8rem; //Laptop: 18px, Ipads: 16.2px, Mobiles: 14.4px
}

strong {
  font-family: $headings-font;
}

// Hero Specific Font Sizes
.eyebrow {
  text-transform: uppercase;
  color: $primary;
  font-weight: 500;
  font-size: 1.46rem;
  line-height: 1.3;
  margin-bottom: 1rem;
  margin-top: 1rem;
  @include media-breakpoint-up(lg) {
    font-size: 1.8rem;
    line-height: 1.33;
  }
}

.subheadline {
  font-size: 1.9rem;
  line-height: 1.32;
}
