footer {
  padding: 5rem 0rem;
  background: #0f1132;
  p {
    color: white;
    font-size: 12px;
  }
  a {
    text-decoration: none;
  }
  .copy-rights {
    font-size: 1.6rem;
    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
    }
  }
  .terms-copy {
    p {
      font-size: 1.3rem;
      line-height: 1.54;
      margin-bottom: 3rem;
    }
  }
  .footer-img {
    img {
      width: 222px;
      @include media-breakpoint-up(md) {
        width: auto;
        max-width: 100%;
      }
    }
  }
}
