// --------------------
// BUTTON STYLE
//---------------------

.btn,
button,
input[type="submit"],
input[type="button"] {
  width: 100%;
  border: 0;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 700;
  font-family: "Futura", sans-serif;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: -0.03em;
  padding: 20px 10px;
  white-space: normal;
  background: $primary;
  color: #fff;
  transition: background-color 0.4s ease-in-out;
  @include media-breakpoint-up(md) {
    padding: 30px;
    font-size: 1.8rem;
  }
  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background: rgba($primary, 0.8);
  }
}

.btn-section {
  margin-top: 1rem;
  @include media-breakpoint-up(md) {
    margin-top: 3rem;
  }
  p {
    width: 90%;
    margin: 0 auto;
    font-size: 1rem;
    color: #414142;
    @include media-breakpoint-up(md) {
      font-size: 1.4rem;
    }
  }
}
