#section-one {
  padding-bottom: 30px;
  h1 {
    color: #ffffff;
  }
  .subheadline {
    color: #ffffff;
  }
  .top-space {
    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
  }
  .ups-wrap {
    .mob {
      width: 240px;
    }
  }
  .vsl-main-vid {
    height: 191px;
    width: 340px;
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
      width: 620px;
      height: 349px;
    }
  }
}

#section-two {
  .brands {
    max-width: 915px;
    width: 100%;
  }
  @include media-breakpoint-down(lg) {
    h3 {
      font-size: 2.4rem;
      line-height: 1.15;
      margin-top: 2rem;
    }
  }
}

#section-four {
  .service-awards {
    width: 503px;
    @include media-breakpoint-only(sm) {
      width: 450px;
    }
  }
  .service-awards-mob {
    width: 152px;
  }
}

#section-five {
  overflow: visible;
  h3 {
    font-size: 2rem;
    line-height: 1.3;
    margin-top: 3rem;
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
      font-size: 1.6rem;
    }
  }
  .logo-deco {
    width: 155px;
    position: absolute;
    bottom: -3rem;
    left: 5%;
    z-index: 2;
    @include media-breakpoint-down(sm) {
      width: 40px;
      bottom: -1rem;
    }
  }
  .first-rentals {
    .first-rentals__card {
      position: relative;
      height: 270px;
      img {
        height: 100%;
      }
      @include media-breakpoint-up(sm) {
        height: 520px;
      }
    }
    .rental-info {
      position: absolute;
      z-index: 5;
      bottom: 5%;
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
      p {
        font-size: 1.5rem;
        color: white;
      }
      strong {
        font-size: 1.6rem;
      }
      @include media-breakpoint-up(md) {
        p {
          color: white;
          font-size: 1.9rem;
        }
        strong {
          font-size: 2rem;
        }
      }
    }
  }
}

#section-six {
  .houses-collage {
    &.add-space-top {
      margin-top: 40px;
      @include media-breakpoint-up(lg) {
        margin-top: 100px;
      }
    }
    img {
      height: 850px;
      object-fit: cover;
    }
  }
}

#section-seven {
  overflow: visible;
  .logo-deco {
    position: absolute;
    bottom: -3rem;
    right: 5%;
    z-index: 2;
    width: auto;
    max-width: 100%;
  }
}

#section-nine {
  .logo-deco {
    position: absolute;
    top: -23px;
    left: 5%;
    width: auto;
    max-width: 100%;
  }
}

#section-ten {
  .background-image-container {
    background: #0f1132;
  }
  .styled-list {
    li {
      background: white;
      padding: 4rem 4rem 4rem 9rem;
      margin-bottom: 1.5rem;
      p {
        font-size: 1.6rem;
        line-height: 1.56;
      }
      strong {
        font-size: 1.6rem;
        line-height: 1.31;
        color: #0f1132;
      }
      @include media-breakpoint-up(md) {
        padding: 4rem 4rem 4rem 12rem;
        p {
          font-size: 1.9rem;
          line-height: 1.32;
        }
        strong {
          font-size: 2rem;
          line-height: 1.49;
          color: #0f1132;
        }
      }
    }
    li:before {
      background: #0f1132;
      color: $primary;
      top: 35%;
      left: 6%;
      height: 55px;
      width: 55px;
      @include media-breakpoint-down(sm) {
        left: 6%;
        top: 14%;
        height: 45px;
        width: 45px;
      }
    }
  }
}

#section-eleven {
  .guarantee-badge img {
    width: auto;
    max-width: 100%;
    @include media-breakpoint-down(sm) {
      width: 261px;
    }
  }
  .t-c {
    font-size: 1.6rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.4rem;
    }
  }
}

#section-twelve {
  background: #0f1132;
  .stars {
    width: auto;
    max-width: 100%;
  }
  .quote-img {
    width: 25px;
    margin-bottom: 3rem;
  }
}

#last-section {
  h2 {
    font-size: 2.6rem;
    line-height: 1.06;
    @include media-breakpoint-up(md) {
      font-size: 4.2rem;
      line-height: 1.21;
    }
  }
  .custom-none {
    @media screen and (min-width: 375px) {
      display: none;
    }
  }
  .icon-home {
    top: 1.7rem;
  }
  .bundle {
    display: flex;
    justify-content: center;
    img {
      width: 331px;
      @include media-breakpoint-up(md) {
        width: 733px;
      }
    }
  }
  .styled-list {
    li {
      img {
        width: 30px;
        margin-right: 2rem;
        @include media-breakpoint-down(sm) {
          width: 21px;
          margin-right: 1rem;
        }
      }
      strong {
        color: #414142;
      }
    }
  }
  .last-heading {
    font-size: 2.4rem;
    line-height: 1.15;
    @include media-breakpoint-up(md) {
      font-size: 3.5rem;
      line-height: 1.11;
    }
  }
}
