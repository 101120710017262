#steps-block {
  h2 {
    margin-bottom: 40px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 60px;
    }
  }
  .step {
    background: #f5f6fa;
    &-number {
      background: $primary;
      color: #fff;
      width: 70px;
      height: 70px;
      line-height: 70px;
      border-radius: 50%;
    }
    &-icon {
      display: flex;
      justify-content: center;
      img {
        max-width: 100%;
        width: auto;
      }
    }
    &-title {
      font-size: 2rem;
      line-height: 1.49;
      @include media-breakpoint-down(sm) {
        font-size: 1.6rem;
        line-height: 1.31;
        width: 88%;
      }
    }
  }
}
