// Global
.swiper-button-next:after,
.swiper-button-prev:after {
  content: "";
}
.swiper-button-prev {
  transform: rotate(180deg);
}

// Video Swiper
.video-slider {
  .swiper-slide {
    .name-stars {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .vid-name {
      line-height: 1.35;
      margin-top: 2rem;
      margin-bottom: 0;
      font-size: 1.6rem;
      @include media-breakpoint-up(sm) {
        font-size: 2.2rem;
      }
    }
    .stars {
      width: 78px;
      margin-top: 1rem;
      @include media-breakpoint-up(sm) {
        width: 138px;
      }
    }
  }

  .nav-container {
    height: 50px;
    display: flex;
    justify-content: space-around;
    .swiper-button-prev,
    .swiper-button-next {
      position: relative;
    }
    .swiper-button-prev {
      left: 15%;
      @include media-breakpoint-up(sm) {
        left: 23%;
      }
      @include media-breakpoint-only(md) {
        left: 18%;
      }
    }
    .swiper-button-next {
      right: 15%;
      @include media-breakpoint-up(sm) {
        right: 23%;
      }
      @include media-breakpoint-only(md) {
        right: 18%;
      }
    }
  }
}

// Testimonials Swiper
.testimonials-slider {
  .swiperTestimonials {
    overflow: hidden;
    height: 100%;
  }
  .swiper-slide {
    flex-direction: column;
    .client-name {
      font-weight: bold;
    }
  }
  .swiper-button-prev {
    left: 5%;
  }
  .swiper-button-next {
    right: 5%;
  }
  @include media-breakpoint-down(sm) {
    .nav-container {
      display: none;
    }
  }
}

// Rental Slider
.rental-slider {
  .rental-swiper {
    overflow: hidden;
    height: 100%;
    .swiper-slide {
      position: relative;
      @include media-breakpoint-up(sm) {
        height: 500px;
      }
    }
  }
  .rental__img {
    width: 100%;
  }
  .rental__details {
    position: absolute;
    bottom: 6%;
    left: 9%;
    z-index: 5;
    p {
      margin-bottom: 0.5rem;
    }
    .address {
      letter-spacing: -0.03rem;
    }
  }
  .nav-container {
    height: 50px;
    display: flex;
    justify-content: space-around;
    margin: 1rem 0;
    .swiper-button-prev,
    .swiper-button-next {
      position: relative;
    }
    .swiper-button-prev {
      left: 15%;
    }
    .swiper-button-next {
      right: 15%;
    }
  }
}
