// --------------------
// GLOBAL COMPONENTS
//---------------------

html {
  scroll-behavior: auto !important;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
}

.f-validate {
  color: red;
  font-size: 1.5rem;
  margin-bottom: 20px;
  margin-top: -10px;
  display: none;
}

.vert-pad {
  padding: 3rem 0 0rem;
  @include media-breakpoint-up(md) {
    padding-top: 5rem;
  }
}

img {
  image-rendering: -webkit-optimize-contrast;
}

.bottom-space {
  margin-bottom: 4rem;
  &-5 {
    margin-bottom: 5rem;
  }
  &-6 {
    margin-bottom: 6rem;
  }
}

.top-space {
  margin-top: 4rem;
  &-5 {
    margin-top: 5rem;
  }
  &-6 {
    margin-top: 6rem;
  }
  @include media-breakpoint-down(sm) {
    margin-top: 2rem;
    &-5 {
      margin-top: 2rem;
    }
    &-6 {
      margin-top: 2rem;
    }
  }
}

.spacer-bottom:after,
.spacer-top:before {
  background-color: $primary;
  content: "";
  display: block;
  height: 1px;
  margin: 2.8rem 0;
  width: 10rem;
}

.spacer-top-zero:before {
  background-color: $primary;
  content: "";
  display: block;
  height: 1px;
  margin-bottom: 2.8rem;
  width: 10rem;
}

.spacer-bottom-dark:after,
.spacer-top-dark:before {
  background-color: #4f4f4f;
  opacity: 0.6;
  content: "";
  display: block;
  height: 1px;
  margin: 1.5rem 0;
  width: 80%;
}

.centered.spacer-bottom:after,
.centered.spacer-top::before,
.spacer-top-zero:before {
  margin-left: auto;
  margin-right: auto;
}

.centered-left.spacer-bottom:after {
  @include media-breakpoint-down(md) {
    margin-left: auto;
    margin-right: auto;
  }
}

.centered.spacer-bottom-dark:after,
.centered.spacer-top-dark:before {
  margin-left: auto;
  margin-right: auto;
}

.centered-left.spacer-bottom-dark:after,
.centered-left.spacer-top-dark:before {
  @include media-breakpoint-down(md) {
    margin-left: auto;
    margin-right: auto;
  }
}

// Generic Section Padding - adds padding to each section - most of your sections should use this - override for special cases
section {
  padding: 60px 0px;
  position: relative;
  overflow: hidden;
  @include media-breakpoint-up(md) {
    padding: 80px 0px;
  }
  @include media-breakpoint-up(lg) {
    padding: 100px 0px;
  }
  &.less-pad {
    padding: 40px 0;
    @include media-breakpoint-up(md) {
      padding: 90px 0;
    }
  }
}

// Background Images - positions the background image behind the content in the section
.background-image-container {
  overflow: hidden;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
  }

  // Background Overlay - Adds a colour overlay with transparency options
  &.semi-transparent-bg {
    &-dark {
      background: #000;
      img {
        opacity: 0.6;
      }
    }
    &-light {
      background: #fff;
      img {
        opacity: 0.4;
      }
    }
  }
}

// Youtube Video Embeds
.video-preview-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  box-shadow: 0px 20px 30px -20px rgba(0, 104, 255, 0.2);
  iframe,
  object,
  embed,
  img.poster,
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0f1132;
    img {
      height: 100%;
    }
  }

  .play-button {
    width: 104px;
    position: absolute;
    left: calc(48% - 50px);
    top: calc(45% - 40px);
    padding: 0;
    margin: 0;
    border: none;
    appearance: none;
    background: none;
    z-index: 5;
    transition: 0.8s ease;

    &:hover {
      transform: scale(1.1);
      cursor: pointer;
    }
    &:focus {
      outline: 0;
    }
  }
}

img {
  width: 100%;
}
.max-width-image {
  width: 100%;
  max-width: 300px;
  @include media-breakpoint-up(md) {
    max-width: 400px;
  }
  @include media-breakpoint-up(lg) {
    max-width: 100%;
  }
}

.container-fluid {
  max-width: 1750px;
}

.color {
  &-primary {
    color: $primary;
  }
  &-secondary {
    color: $secondary;
  }
}

.mt-8 {
  margin-top: 40px;
  @include media-breakpoint-up(lg) {
    margin-top: 60px;
  }
}