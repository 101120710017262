form {
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea,
  select {
    width: 100%;
    padding: 20px 15px 20px 50px;
    box-shadow: 30px 26px 40px -20px rgba(153, 153, 153, 0.08);
    border: none;
    margin-bottom: 15px;
    border-radius: 5px;
    font-size: 1.2rem;
    line-height: 1.32;
    &::placeholder {
      color: #414142;
      font-size: 1.2rem;
    }
    @include media-breakpoint-up(md) {
      padding: 20px 15px 20px 60px;
      font-size: 1.9rem;
      &::placeholder {
        font-size: 1.9rem;
      }
    }
    &:-internal-autofill-selected {
      background-color: $white !important;
      background-image: none !important;
      color: #414142;
    }
  }

  .radio-wrap {
    display: flex;
    padding: 20px 15px 20px 50px;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      padding: 20px 15px 20px 60px;
    }
    .radio-title {
      color: #414142;
      font-size: 1.2rem;
      line-height: 1.32;
      margin-bottom: 0.5rem;
      @include media-breakpoint-up(md) {
        font-size: 1.9rem;
      }
    }
    input[type="radio"] {
      width: 24px;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 0.2rem;
    }

    .form-radio {
      color: #414142;
      font-size: 1.2rem;
      line-height: 1.32;
      margin-bottom: 0.4rem;
      @include media-breakpoint-up(md) {
        font-size: 1.9rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  textarea {
    height: 210px !important;
  }

  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: #414142;
    font-family: inherit;
    font-size: 1.2rem;
    cursor: inherit;
    @include media-breakpoint-up(md) {
      font-size: 1.9rem;
    }
  }

  .form-group {
    position: relative;
    width: 100%;
    margin-bottom: 0;
    line-height: 1;
    .icon {
      position: absolute;
      top: 20px;
      left: 15px;
      width: auto;
      max-width: 100%;
      z-index: 999;
      @include media-breakpoint-up(md) {
        left: 25px;
      }
    }
  }

  label.error,
  .field-error {
    display: block;
    margin-bottom: 20px;
    margin-top: -10px;
    color: red;
    font-size: 1.5rem;
  }
  .form-messages {
    text-align: center;
    &.success {
      color: green;
    }
    &.error {
      color: red;
    }
  }
}
