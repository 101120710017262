.metric-block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #e9eaf167;
  padding: 65px 30px;
  h3 {
    margin-bottom: 10px;
  }
  @include media-breakpoint-down(lg) {
    margin-bottom: 20px;
  }
}