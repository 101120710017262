body.modal-open {
  height: 100vh;
  overflow-y: hidden;

  .modal {
    background: rgba(0, 0, 0, 0.8);
    .modal-dialog {
      max-width: 90%;
      @include media-breakpoint-up(md) {
        max-width: 700px;
      }
    }
    .modal-dialog,
    .modal-content {
      height: auto;
    }

    .modal-header {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 2rem 1rem 0 1rem;
      border: none;
      h5 {
        font-size: 2rem;
        line-height: 1.11;
        margin-bottom: 1rem;
      }
      p {
        font-size: 1.6rem;
        line-height: 1.32;
      }
      @include media-breakpoint-up(sm) {
        padding: 6rem 6rem 0 6rem;
        h5 {
          font-size: 3.5rem;
          line-height: 1.11;
        }
        p {
          font-size: 1.9rem;
          line-height: 1.32;
        }
      }
    }
    .modal-body {
      padding: 0rem 1rem 1rem;
      @include media-breakpoint-up(sm) {
        padding: 3rem 6rem 6rem 6rem;
      }
    }

    .close {
      top: 10px;
      right: 10px;
      z-index: 999;
      width: 40px;
      height: 40px;
      text-align: center;
      background-color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
