body.ebook-page {
  #hero {
    h1 {
      margin-bottom: 2rem;
    }
    .subheadline {
      margin-bottom: 2rem;
    }
    .book-cover {
      @include media-breakpoint-down(sm) {
        width: 75%;
      }
    }
    .ups-wrap {
      max-width: 859px;
      width: 100%;
    }
    .btn-col {
      @include media-breakpoint-only(md) {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
  }
  #ebook-third-section {
    overflow: visible;
    background: white;
    .styled-list {
      li {
        background: #f1f3fb;
        padding: 3rem 3rem 3rem 8rem;
        p {
          font-size: 1.6rem;
          line-height: 1.56;
        }
        strong {
          font-size: 1.6rem;
          line-height: 1.31;
          color: #0f1132;
        }
        @include media-breakpoint-up(md) {
          padding: 5rem 5rem 5rem 12rem;
          p {
            font-size: 1.9rem;
            line-height: 1.32;
          }
          strong {
            font-size: 2rem;
            line-height: 1.49;
            color: #0f1132;
          }
        }
      }
      li:before {
        background: #0f1132;
        color: #b1924e;
        font-size: 2rem;
        height: 45px;
        left: 6%;
        line-height: 1;
        top: 15%;
        width: 45px;
        @include media-breakpoint-up(md) {
          width: 65px;
          height: 65px;
          font-size: 3.5rem;
          top: 30%;
          left: 4%;
        }
      }
      .last-item {
        padding: 2rem 4rem 2rem 10rem;
      }
      .last-item:before {
        display: none;
      }
    }
    .logo-deco {
      width: auto;
      max-width: 100%;
      position: absolute;
      right: 3%;
      top: -3%;
      z-index: 3;
    }
  }
  .ebook {
    padding: 30px 0;
    .last-ebook {
      width: 400px;
    }
  }
}

/*
Form code: 
https://drive.google.com/drive/folders/1UlDhXdO8gruyiYDN5VtSpY6ap7dHautg?usp=sharing

If not working, try stripping it off from here:
https://form.jotform.com/213327433189154

Form code: 
https://drive.google.com/drive/folders/1UlDhXdO8gruyiYDN5VtSpY6ap7dHautg?usp=sharing

If not working, try stripping it off from here:
https://form.jotform.com/213327433189154
*/
