.award-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  img {
    width: auto;
    max-width: 100%;
    @include media-breakpoint-up(md) {
      max-width: 50%;
    }
  }
  &.bottom-row {
    box-shadow: 0px 26.0162px 44.8554px rgba(0, 0, 0, 0.03);
    padding: 20px;
    margin-bottom: 10px;
    @include media-breakpoint-only(md) {
      margin-bottom: 30px;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      padding: 20px 0;
    }
  }
}

.awards-spacer {
  width: 10rem;
  background-color: #B1924E;
  margin: 20px 0;
  height: 1px;
  @include media-breakpoint-up(md) {
    margin: 60px 0;
  }
}