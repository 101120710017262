.article-snippets {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #F5F6FA 100%);
  h2 {
    margin-bottom: 50px;
  }
  .article-img {
    display: flex;
    justify-content: center;
    picture {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    img {
      height: 100%;
      max-height: 814px;
      max-width: 1068.39px;
    }
  }
}