header {
  padding: 15px;
  background-color: transparent;
  &.scrolled {
    box-shadow: -3px -3px 6px 8px rgba(0, 0, 0, 0.05);
    background-color: $headerbg;
    transition: 0.2s ease-in-out;
    background: rgba(15, 17, 50, 1);
    .phone-icon.mob {
      background: #b1924e;
      transition: all ease 0.5s;
      
    }
    .fill-white {
      fill: white;
      transition: all ease 0.5s;
    }
   
    span, em {
      color: white;
      transition: all ease 0.5s;
    }
  }
  .first-row > * {
    width: auto;
  }
  a {
    text-decoration: none;
  }
  .first-row {
    @include media-breakpoint-down(sm) {
      flex-wrap: nowrap;
    }
  }
}

.header-logo img {
  transition: 0.2s ease-in-out;
  // Inverts the logo colour. You could use the filter options to modify a transparent png logo for the different colour header bg.
  // filter: invert(1);

  // Default to small scrolled size on mobile
  width: $header-scrolled-width;
  @include media-breakpoint-down(sm) {
    width: 130px;
  }

  // Turn on the header resize on desktop
  @include media-breakpoint-up(md) {
    width: $header-starting-width;
  }
  .scrolled & {
    // filter: none;
    // Turn on the header resize on desktop
    @include media-breakpoint-up(md) {
      width: $header-scrolled-width;
    }
  }
}

.header-phone {
  line-height: 1.2;

  .phone-icon {
    img,
    svg {
      max-width: 35px;
      width: 100%;
    }
  }

  .phone-icon.mob {
    width: 115px;
    border: 1px solid;
    padding: 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease 0.5s;
    span {
      font-size: 11px;
      margin-left: 5px;
    }
    img,
    svg {
      max-width: 15px;
      width: 100%;
    }
  }

  .phone-text {
    padding-left: 10px;
  }

  // Got questions (if it exists)
  span {
    font-weight: 500;
    font-size: 15px;
  }
  // Phone number text
  em {
    font-style: normal;
    font-weight: bold;
    font-size: 2.05rem;
    line-height: 1.1;
    color: white;
  }
}
